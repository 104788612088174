import { Link } from "gatsby"
import React from "react"
import square from "../../../images/node-js/square.svg"
import Vector from "../../../images/node-js/Vector.svg"
import react1 from "../../../images/node-js/react1.svg"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import * as styles from "./SubIntro.module.scss"

const SubIntro = ({ strapiData }) => {
  return (
    <div className={`p-85 ${styles.subIntro}`}>
      <img
        className={styles.square}
        src={square}
        decoding="async"
        loading="lazy"
        alt="background"
      />
      <Container>
        <img
          className={styles.react}
          src={react1}
          decoding="async"
          loading="lazy"
          alt="background"
        />
        <div className={styles.heading}>
          {" "}
          <div dangerouslySetInnerHTML={{ __html: strapiData?.title }} />
          <div
            dangerouslySetInnerHTML={{
              __html: strapiData?.description?.description,
            }}
          />
        </div>
        <div className={styles.nodeJs}>
          {" "}
          <Row className="gap-30 align-items-center">
            <Col xl={4} lg={12}>
              <div className={styles.content}>
                {strapiData?.cards &&
                  strapiData?.cards?.map(
                    (el, i) =>
                      i < 3 && (
                        <div className={styles.subHeadings}>
                          <h3>
                            <span>{el?.title}</span>
                          </h3>
                          <p
                            dangerouslySetInnerHTML={{
                              __html: el?.description?.description,
                            }}
                          />
                        </div>
                      )
                  )}
              </div>
            </Col>
            <Col xl={4} lg={12}>
              <div className={styles.nodejsImg}>
                <img
                  decoding="async"
                  loading="lazy"
                  src={strapiData?.secImages[0]?.localFile.publicURL}
                  alt={"Why should you Choose for your next project?"}
                />
              </div>
            </Col>
            <Col xl={4} lg={12}>
              <div className={styles.content}>
                {strapiData?.cards &&
                  strapiData?.cards?.map(
                    (el, i) =>
                      i >= 3 && (
                        <div className={styles.subHeadings}>
                          <h3>
                            <span>{el?.title}</span>
                          </h3>
                          <p
                            dangerouslySetInnerHTML={{
                              __html: el?.description?.description,
                            }}
                          />
                        </div>
                      )
                  )}
              </div>
            </Col>
            <div className={styles.nodeJsBtn}>
              {strapiData?.buttons[0] && (
                <Link
                  to={strapiData?.buttons[0]?.url}
                  className="btn_black_border"
                >
                  {strapiData?.buttons[0]?.title}
                </Link>
              )}
            </div>
          </Row>
        </div>
      </Container>
      <img
        className={styles.Vector}
        src={Vector}
        decoding="async"
        loading="lazy"
        alt="background"
      />
    </div>
  )
}

export default SubIntro
