import { graphql } from "gatsby"
import React from "react"
import Faqs from "../components/common/Faqs2"
import SEORevamp from "../components/common/SEO_Revamp"
import Achieve from "../components/common/nodejs/Achieve"
import Banner from "../components/common/nodejs/Banner"
import Benefits from "../components/common/nodejs/Benefits"
import CreateOwn from "../components/common/nodejs/CreateOwn"
import Goals from "../components/common/nodejs/Goals"
import SubIntro from "../components/common/nodejs/SubIntro"
import SliderNode from "../components/common/nodejs/sliderNode"
import MainLayout from "../layouts/MainLayout"
export const Head = ({ data }) => {
  const { metaTitle, metaDescription } = data?.strapiPage?.seo
  let headSchema = []
  const bodySchema = data?.strapiPage?.seo?.schemas?.filter(v => {
    return v?.visibilityIn === true ? v : headSchema?.push(v) && false
  })
  return (
    <SEORevamp
      title={metaTitle}
      description={metaDescription}
      schemas={headSchema}
      image="https://invozone-backend.s3.amazonaws.com/hire_node_js_developer_2f8f502127.webp"
    />
  )
}
const Node = ({ data }) => {
  // console.log(data)
  const banner = data?.strapiPage?.sections[0]
  const dilver = data?.strapiPage?.sections[1]
  const achieve = data?.strapiPage?.sections[2]
  const partner = data?.strapiPage?.sections[3]
  const nodeJs = data?.strapiPage?.sections[4]
  const hireNode = data?.strapiPage?.sections[5]
  const success = data?.strapiPage?.sections[6]
  const faqs = data?.strapiPage?.sections[7]

  let headSchema = []
  const bodySchema = data?.strapiPage?.seo?.schemas?.filter(v => {
    return v?.visibilityIn === true ? v : headSchema?.push(v) && false
  })

  return (
    <MainLayout bgChanged={false} schemas={bodySchema}>
      <Banner strapiData={banner} />
      <Goals strapiData={dilver} />
      <Achieve strapiData={achieve} />
      <CreateOwn strapiData={partner} />
      <SubIntro strapiData={nodeJs} />
      <Benefits strapiData={hireNode} />
      <SliderNode strapiData={success} />
      <Faqs strapiData={faqs} />
    </MainLayout>
  )
}

export const query = graphql`
  query node {
    strapiPage(slug: { eq: "nodejs2" }) {
      sections {
        title
        cards {
          title
          subTitle
          description: childStrapiComponentCardsCardDescriptionTextnode {
            description
          }
          image1 {
            localFile {
              publicURL
            }
          }
          image2 {
            localFile {
              publicURL
            }
          }
        }
        secImages {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
            publicURL
          }
          url
        }
        buttons {
          title
          url
        }
        description: childStrapiComponentSectionsSectionDescriptionTextnode {
          id
          description
        }
      }
      seo {
        metaTitle
        metaDescription
        schemas {
          visibilityIn
          structuredData
        }
      }
    }
  }
`

export default Node
