import { Link } from "gatsby"
import React from "react"
import partnerLines from "../../../images/node-js/partnerLines.svg"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import * as styles from "./CreateOwn.module.scss"

const CreateOwn = ({ strapiData }) => {
  return (
    <div className={`p-85 ${styles.head}`}>
      <img
        className={styles.partnerLines}
        src={partnerLines}
        decoding="async"
        loading="lazy"
        alt="background"
      />
      <Container>
        <div
          className={styles.heading}
          dangerouslySetInnerHTML={{ __html: strapiData?.title }}
        />
        <Row className="gap-30">
          {strapiData?.cards &&
            strapiData?.cards?.map((item, index) => {
              const { description, title } = item
              return (
                <Col
                  lg={6}
                  md={6}
                  xs={12}
                  key={index}
                  style={{ display: "flex" }}
                >
                  <div className={styles.box}>
                    <h3>{title}</h3>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: description?.description,
                      }}
                    />
                  </div>
                </Col>
              )
            })}
        </Row>
        <div className={styles.futureBtn}>
          {strapiData?.buttons[0] && (
            <Link to={strapiData?.buttons[0]?.url} className="btn_black_border">
              {strapiData?.buttons[0]?.title}
            </Link>
          )}
        </div>
      </Container>
    </div>
  )
}

export default CreateOwn
