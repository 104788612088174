// extracted by mini-css-extract-plugin
export var Vector = "SubIntro-module--Vector--80a6a";
export var bulletPoint = "SubIntro-module--bulletPoint--5c4e0";
export var content = "SubIntro-module--content--2491c";
export var description = "SubIntro-module--description--92aae";
export var heading = "SubIntro-module--heading--2881c";
export var nodeJs = "SubIntro-module--nodeJs--9919c";
export var nodeJsBtn = "SubIntro-module--nodeJsBtn--00421";
export var nodejsImg = "SubIntro-module--nodejsImg--c9cf5";
export var react = "SubIntro-module--react--71b89";
export var square = "SubIntro-module--square--1c93c";
export var subHeadings = "SubIntro-module--subHeadings--aa3ec";
export var subIntro = "SubIntro-module--subIntro--c990c";