import { Link } from "gatsby"
import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import * as styles from "../nodejs/Banner.module.scss"

const Banner = ({ strapiData }) => {
  return (
    <section className={styles.banner}>
      <Container>
        <Row className="align-items-center gap-30 ">
          <Col lg={6} md={12}>
            <div className={styles.heading}>
              {" "}
              <h1 dangerouslySetInnerHTML={{ __html: strapiData?.title }} />
              <p
                dangerouslySetInnerHTML={{
                  __html: strapiData?.description?.description,
                }}
              />
              <div>
                <ul className="pr-2 pr-md-0">
                  {strapiData?.cards &&
                    strapiData?.cards?.map(el => (
                      <Row className="pb-2" key={el?.title}>
                        <Col xs={1} className="pr-0">
                          <div className={styles.liBullet} />
                        </Col>
                        <Col xs={11} className="px-0">
                          <li>{el?.title}</li>
                        </Col>
                      </Row>
                    ))}
                </ul>
              </div>
            </div>
            <div className="pt-4">
              {strapiData?.buttons[0] && (
                <Link
                  to={strapiData?.buttons[0]?.url}
                  className="btn_black_border"
                >
                  {strapiData?.buttons[0]?.title}
                </Link>
              )}
            </div>
          </Col>
          <Col
            lg={6}
            md={12}
            className="text-center text-lg-right mt-5 mt-md-0"
          >
            <img
              className={styles.bannerImg}
              src={
                strapiData?.secImages &&
                strapiData?.secImages[0]?.localFile?.publicURL
              }
              alt=" Hire Node.js Developers "
              width="100%"
              decoding="async"
              loading="lazy"
              height="auto"
            />
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default Banner
