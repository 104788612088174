import { Link } from "gatsby"
import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import border from "../../../images/node-js/border.svg"
import * as styles from "./Benefits.module.scss"
import "./Benefits.scss"

const Benefits = ({ mvc, ruby, strapiData }) => {
  return (
    <div className={`${styles.benefit} ${mvc ? styles.mvc : ""}`}>
      <Container>
        <div
          className={styles.heading}
          dangerouslySetInnerHTML={{ __html: strapiData?.title }}
        />
        <div
          className={styles.desc}
          dangerouslySetInnerHTML={{
            __html: strapiData?.description?.description,
          }}
        />

        {ruby ? (
          <div className={styles.imageBox}>
            <img
              decoding="async"
              loading="lazy"
              src={strapiData?.secImages[0]?.localFile?.publicURL}
              alt={"Ruby on Rails developers"}
            />
            <img
              decoding="async"
              loading="lazy"
              src={strapiData?.secImages[1]?.localFile?.publicURL}
              alt={"Ruby on Rails developers"}
            />
          </div>
        ) : (
          <Row
            className={`gap-50 sds ${
              mvc ? "justify-content-between" : "justify-content-center"
            }`}
          >
            {strapiData?.cards &&
              strapiData?.cards?.map((e, i) => (
                <Col lg={2} md={6} xs={12} key={i}>
                  <div className={styles.boxParent}>
                    <span style={{ backgroundColor: `${e.subTitle}` }}></span>
                    <img
                      decoding="async"
                      loading="lazy"
                      src={e?.image2[0]?.localFile?.publicURL}
                      alt="border"
                      className={styles.top}
                    />
                    <div className={styles.box1}>
                      <div className={styles.box2}>
                        <img
                          decoding="async"
                          loading="lazy"
                          src={border}
                          alt={e?.title}
                        />
                        <img
                          decoding="async"
                          loading="lazy"
                          src={e?.image1[0]?.localFile?.publicURL}
                          alt={e?.image2[0]?.alternativeText}
                        />
                      </div>
                    </div>
                    <h3 className={styles.text}>{e.title}</h3>
                  </div>
                </Col>
              ))}
          </Row>
        )}
        <div className={styles.button}>
          {strapiData?.buttons[0] && (
            <Link to={strapiData?.buttons[0]?.url} className="btn_black_border">
              {strapiData?.buttons[0]?.title}
            </Link>
          )}
        </div>
      </Container>
    </div>
  )
}

export default Benefits
