// extracted by mini-css-extract-plugin
export var arrowContainer = "sliderNode-module--arrowContainer--5ae3d";
export var card = "sliderNode-module--card--62ab9";
export var description = "sliderNode-module--description--bc276";
export var heading = "sliderNode-module--heading--26e82";
export var imageActive = "sliderNode-module--imageActive--e4d06";
export var imageContainer = "sliderNode-module--imageContainer--56740";
export var imageNotActive = "sliderNode-module--imageNotActive--d79f7";
export var mainContainer = "sliderNode-module--mainContainer--2399c";
export var mainImage = "sliderNode-module--mainImage--de4a7";
export var portfolioArrowIcon = "sliderNode-module--portfolioArrowIcon--32092";
export var portfolioArrowIconCover = "sliderNode-module--portfolioArrowIconCover--801b8";
export var portfolioArrowIconleft = "sliderNode-module--portfolioArrowIconleft--e129c";
export var portfolioArrowRightIconCover = "sliderNode-module--portfolioArrowRightIconCover--15ac1";
export var sliderHead = "sliderNode-module--sliderHead--210a0";