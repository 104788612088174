// extracted by mini-css-extract-plugin
export var benefit = "Benefits-module--benefit--acd44";
export var box1 = "Benefits-module--box1--c2031";
export var box2 = "Benefits-module--box2--4d293";
export var boxParent = "Benefits-module--boxParent--7ed00";
export var button = "Benefits-module--button--eedc8";
export var desc = "Benefits-module--desc--4c575";
export var heading = "Benefits-module--heading--44040";
export var imageBox = "Benefits-module--imageBox--e7cd4";
export var mvc = "Benefits-module--mvc--835e6";
export var text = "Benefits-module--text--11b14";
export var top = "Benefits-module--top--a0dce";