import React from "react"
import square from "../../../images/node-js/square.svg"
import Vector from "../../../images/node-js/Vector.svg"
import Vector2 from "../../../images/node-js/Vector2.svg"
import react1 from "../../../images/node-js/react1.svg"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import * as styles from "./Achieve.module.scss"

const Achieve = ({ strapiData }) => {
  return (
    <div className={`${styles.subservice}`}>
      <img
        className={styles.square}
        src={square}
        decoding="async"
        loading="lazy"
        alt="background"
      />
      <Container>
        <img
          decoding="async"
          loading="lazy"
          className={styles.Vector2}
          src={Vector2}
          alt="background"
        />
        <img
          className={styles.react}
          src={react1}
          decoding="async"
          loading="lazy"
          alt="background"
        />
        <div
          className={styles.heading}
          dangerouslySetInnerHTML={{ __html: strapiData?.title }}
        />
        <div
          className={styles.description}
          dangerouslySetInnerHTML={{
            __html: strapiData?.description?.description,
          }}
        />
        <Row className={`gap-30 ${styles.cardWrapper}`}>
          {strapiData?.cards &&
            strapiData?.cards?.map((e, i) => (
              <Col lg={4} md={6} xs={12} key={i} className="d-flex">
                <div className={styles.card}>
                  <img
                    src={e?.image1[0]?.localFile?.publicURL}
                    alt={e?.title}
                    decoding="async"
                    loading="lazy"
                  />
                  <h3>{e?.title}</h3>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: e?.description?.description,
                    }}
                  />
                </div>
              </Col>
            ))}
        </Row>
      </Container>
      <img
        className={styles.Vector}
        src={Vector}
        decoding="async"
        loading="lazy"
        alt="background"
      />
    </div>
  )
}

export default Achieve
