import React from "react"
import circle from "../../../images/node-js/circle.svg"
import Vector from "../../../images/node-js/Vector.svg"
import Vector2 from "../../../images/node-js/Vector2.svg"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import * as styles from "./Goals.module.scss"

const Goals = ({ strapiData }) => {
  return (
    <div className={`${styles.subservice}`}>
      <img
        decoding="async"
        loading="lazy"
        className={styles.Vector2}
        src={Vector2}
        alt="background"
      />
      <Container>
        <div
          className={styles.heading}
          dangerouslySetInnerHTML={{ __html: strapiData?.title }}
        />
        <Row className="gap-30">
          {strapiData?.cards &&
            strapiData?.cards?.map((el, i) => (
              <Col xs={12} md={6} lg={3} key={i}>
                <div className={styles.subCard}>
                  <div className={styles.content}>
                    <img
                      decoding="async"
                      loading="lazy"
                      src={el?.image1[0]?.localFile?.publicURL}
                      alt={el?.title}
                    />
                    <h3>{el?.title}</h3>
                  </div>
                </div>
              </Col>
            ))}
        </Row>
        <img
          className={styles.circle}
          src={circle}
          decoding="async"
          loading="lazy"
          alt="background"
        />
        <img
          className={styles.Vector}
          src={Vector}
          decoding="async"
          loading="lazy"
          alt="background"
        />
      </Container>
    </div>
  )
}

export default Goals
